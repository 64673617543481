
const initState = {
    me: null,
    isAuthorizing: false,
    isAuthorized: false,
    isSessionInvalid: false,
};

export default function authReducer(state = initState, action) {
    const { type, payload } = action;

    // Status code 209 is a special code from Parse, used when the session is invalid or expired.
    if (payload?.code === 209) {
        return {
            ...state,
            me: null,
            isAuthorizing: false,
            isAuthorized: false,
            isSessionInvalid: true,
        };
    }

    switch (type) {
        case 'LOGIN_USER_PENDING': {
            return {
                ...state,
                isAuthorizing: true,
            };
        }

        case 'LOGOUT_USER_PENDING':
        case 'LOGIN_USER_REJECTED': {
            return {
                ...state,
                me: null,
                isAuthorizing: false,
                isAuthorized: false,
                isSessionInvalid: true,
            };
        }

        case 'RESTORE_USER':
        case 'LOGIN_USER_FULFILLED': {

            if (!payload) {
                return {
                    ...state,
                    me: null,
                    isAuthorizing: false,
                    isAuthorized: false,
                    isSessionInvalid: true,
                };
            }

            return {
                ...state,
                me: payload,
                isAuthorizing: false,
                isAuthorized: true,
                isSessionInvalid: false,
            };
        }

        default: {
            return state;
        }
    }
}
